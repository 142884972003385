export default {
  appName: '悟空图像',
  home: '首页',
  personalCenter: '用户中心',
  search: '搜索',
  picture: '图片',
  template: '模板',
  magic: '幻影',
  frame: '边框',
  sticker: '贴纸',
  animation: '动画',
  soundEffect: '音频',
  sprites: '角色',
  recommend: '精选',
  appDownload: 'APP下载',
  loginLogon: '登录/注册',
  tip: '提示',
  confirm: '确定',
  cancel: '取消',
  login: '登录',
  logout: '退出登录',
  logoutTip: '此操作将退出登录, 是否继续?',
  code115: '账号不存在',
  code117: '密码错误',
  code118: '手机号格式错误',
  code123: '验证码错误',
  code900: '亲，今天赞人家5次就够了呢',
  bannserTextT1: '海量高品质素材让您的创作更加出彩',
  bannserTextT2: '高清画质保证    模板可分层编辑',
  aboutSearch: '搜索约',
  resultSearch: '条结果',
  searchRecords: '搜索记录：',
  clearHistory: '清空历史',
  everyoneIsSearching: '大家都在搜：',
  inputCode: '请输入验证码',
  getCode: '获取验证码',
  inputPassword: '请输入密码',
  password: '密码',
  codeError: '验证码错误！！',
  accountError: '请输入正确的账号！',
  codeSent: '短信验证码已发送!',
  QQ: '公司客服QQ群:461444765',
  eMail: "邮箱：company{'@'}photosir.cn",
  p2: "版权所有 亦心科技 Copyright © 2022 {'|'} ",
  p2visual: "版权所有 亦心科技 Copyright © 2023 {'|'} ",
  privacyPolicy: '隐私政策',
  p3: " {'|'} 京ICP备20012926号",
  findMoreMaterial: '热门素材',
  VCGPhotoSir: 'VCG X 悟空商用素材',
  more: '更多>',
  getpage: '获得商用授权',
  photoSirVCG: '悟空图像×视觉中国',
  insufficientTimes: '您本月的下载次数不足！',
  getFrequency: '获取更多次数',
  photoSirMaterialsCopyrightAgreement: '《悟空图像素材版权协议》',
  designTemplate: '设计模板',
  creativeTopics: '特色专区',
  downloadNow: '立即下载',
  codeLogin: '验证码登录',
  accountLogin: '账号密码登录',
  apply: '注册',
  inputPhone: '请输入手机号',
  thirdPartyLogin: '第三方登录',
  loginAgreement: '登录即视为同意',
  agreement: '悟空图像软件使用许可及服务协议',
  and: '和',
  privacyPolicyUrl: '/static/privacy_policy_zh-cn.html',
  userAgreementUrl: '/static/user_agreement_zh-cn.html',
  privacyPolicyProgramUrl: '/static/privacy_policy_program_zh-cn.html',
  close: '关 闭',
  charactersLong: '长度在11个字符',
  accountCannotBeEmpty: '账号不能为空',
  downloading: '下载中',
  label: '标签：',
  classification: '分类：',
  noData: '暂无数据',
  all: '全部',
  hottest: '最热',
  latest: '最新',
  pictureID: '图片ID：',
  maxSize: '最大尺寸：',
  storageFormat: '存储格式：',
  spritesMaterial: '角色素材',
  recommendPrograms: '精选程序',
  courseware: '精选课件',
  discipline: '学科对象',
  cc0: 'CC0素材库',
  cc0new: '商免素材上新',
  cc0detail1: '10000+免费素材可用于企业商用',
  cc0detail2: '海量素材让您的创作更出彩',
  exemption: '免费商用'
}
