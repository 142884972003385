import { createRouter, createWebHashHistory } from 'vue-router'

/* 总路由--天宫 */
const routes = [
  {
    path: '/front',
    name: 'Front',
    component: () => import('../views/Layout/layoutMain.vue'),
    redirect: '/front/indexAppHome',
    children: [
      {
        // App首页 (localStorage中的productstype=1(默认值))
        path: 'indexAppHome',
        name: 'indexAppHome',
        component: () => import('../views/Index/homeView.vue')
      },
      {
        // App首页 (localStorage中的productstype=1(默认值))
        path: 'IndexApp',
        name: 'IndexApp',
        component: () => import('../views/Index/homeView.vue')
      },
      {
        // App首页 教育版本 (localStorage中的productstype=10)
        path: 'indexEduHome',
        name: 'indexEduHome',
        component: () => import('../views/Index/homeView.vue')
      },
      {
        // App首页 联想版本 (localStorage中的productstype=14)
        path: 'indexLevelHome',
        name: 'indexLevelHome',
        component: () => import('../views/Index/homeView.vue')
      },
      {
        // App首页 编程版本 (localStorage中的productstype=16)
        path: 'indexVisualHome',
        name: 'indexVisualHome',
        component: () => import('../views/Index/homeView.vue')
      },
      {
        // 搜索结果
        path: 'Search',
        name: 'Search',
        component: () => import('../views/Group/sortPage.vue')
      },
      {
        // 分类页面
        path: 'sortPage',
        name: 'sortPage',
        component: () => import('../views/Group/sortPage.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  linkActiveClass: 'routerActive',
  routes
})

/* 路由守卫 */
router.beforeEach((to, from, next) => {
  const toName = to.name
  if (!to.meta.title) {
    window.document.title = '天宫'
  } else {
    window.document.title = to.meta.title
  }
  // iframe 中 登录页要切换rem基数值，否则消息提示弹窗过小(看不清)
  if (toName === 'UserLogin') {
    window.resetRem(75)
  } else {
    window.resetRem(19)
  }
  next()
})

export default router
