export default {
  appName: 'PhotoSir',
  home: 'Home',
  personalCenter: 'Personal center',
  search: 'Search',
  picture: 'Picture',
  template: 'Template',
  magic: 'Mirage',
  frame: 'Frame',
  sticker: 'Sticker',
  animation: 'Animation',
  soundEffect: 'Sound effect',
  sprites: 'Sprites',
  recommend: 'Recommend',
  appDownload: 'App download',
  loginLogon: 'Login/Logon',
  tip: 'Tip',
  confirm: 'Confirm',
  cancel: 'Cancel',
  login: 'Login',
  logout: 'Logout',
  logoutTip: 'This operation will exit the login. Do you want to continue?',
  code115: 'Account does not exist',
  code117: 'Password error',
  code118: 'Mobile phone number format error',
  code123: 'Verification code error',
  code900: 'Dear, it\'s enough to praise (him / her) five times today',
  bannserTextT1: 'Abundant HD materials let your arts shine',
  bannserTextT2: 'High definition guarantee Edit templates by layers',
  aboutSearch: 'About',
  resultSearch: 'results',
  searchRecords: 'Search records: ',
  clearHistory: 'Clear history',
  everyoneIsSearching: 'Hot Trends...',
  inputCode: 'Please enter verification code',
  getCode: 'Get verification code',
  inputPassword: 'Please enter password',
  password: 'Password',
  codeError: 'Verification code error!',
  accountError: 'Account error!',
  codeSent: 'SMS verification code has been sent!',
  QQ: 'Company customer service QQ home: 461444765',
  eMail: "email: Support{'@'}yeeheart.com",
  p2: 'Copyright Yeeheart Technology  2023 |',
  privacyPolicy: 'Privacy policy',
  p3: ' | Jing ICP Bei No. 20012926',
  findMoreMaterial: 'Find more material',
  VCGPhotoSir: 'VCG X Commercial Materials by PhotoSir',
  more: 'More>',
  getpage: 'Obtain authorization',
  photoSirVCG: 'PhotoSir X VCG',
  insufficientTimes: 'You’ve run out download times this month!',
  getFrequency: 'Recharge to get more',
  photoSirMaterialsCopyrightAgreement: '《PhotoSir Materials Copyright Agreement》',
  designTemplate: 'Design template',
  creativeTopics: 'Creative topics',
  downloadNow: 'Download',
  codeLogin: 'Verification code login',
  accountLogin: 'Account password login',
  apply: 'Apply to join',
  inputPhone: 'Please enter phone number',
  thirdPartyLogin: 'Third party login',
  loginAgreement: 'By logging in, you agree to ',
  agreement: 'PhotoSir software license and service agreement',
  and: ' and ',
  privacyPolicyUrl: '/static/privacy_policy_en.html',
  userAgreementUrl: '/static/user_agreement_en.html',
  privacyPolicyProgramUrl: '/static/privacy_policy_program_en.html',
  close: 'Close',
  charactersLong: '11 characters long',
  accountCannotBeEmpty: 'Account cannot be empty',
  downloading: 'Downloading',
  label: 'Label：',
  classification: 'Classification: ',
  noData: 'No data',
  all: 'All',
  hottest: 'hottest',
  latest: 'latest',
  pictureID: 'Picture ID: ',
  maxSize: 'Maximum size: ',
  storageFormat: 'Storage format: ',
  spritesMaterial: 'Sprites Material',
  recommendPrograms: 'Recommend Programs',
  courseware: '精选课件',
  discipline: '学科对象',
  cc0: 'CC0素材库',
  cc0new: '商免素材上新',
  cc0detail1: '10000+免费素材可用于企业商用',
  cc0detail2: '海量素材让您的创作更出彩',
  exemption: 'Commercial Exemption'
}
